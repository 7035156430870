
// Angular
import { CommonModule } from '@angular/common';
import { Component, OnInit, WritableSignal, signal } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

// Ionic
import { IonicModule, MenuController } from '@ionic/angular';

// NgRx
import { Store } from '@ngrx/store';
import { distinctUntilChanged } from 'rxjs';

// Capacitor
import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import { SplashScreen } from '@capacitor/splash-screen';
import {
  AppTrackingStatusResponse,
  AppTrackingTransparency,
} from 'capacitor-plugin-app-tracking-transparency';

// Lessor
import { SideMenuService } from '@lessor/core/services/side-menu/side-menu.service';
import packageJson from '@lessor/packagejson';

// Libs
import { AnalyticsService } from '@libs/analytics';
import { AuthFacade } from '@libs/auth-lib';
import { CrashlyticsService } from '@libs/crashlytics';
import {
  Canal,
  LessorUser,
  LocalStorageItem,
  SettingsSocialSport,
  SideMenu,
} from '@libs/entity-lib';
import { NotificationListenersService } from '@libs/notifications';
import {
  selectResponsavelName,
  selectSportCenterImage,
  selectSportCenterName,
  selectSportCenterUsername,
  setProfileLessor,
  setSettingsSocialSport,
} from '@libs/settings-state';
import { lessorEnvironment } from '@libs/shared/util-environments';
import { TranslationLibModule } from '@libs/translation';
import { PlatformDetectionService, VibrationService } from '@libs/util-services';

// Others
import { configureIcons } from './config/icon-config';
import { DialogService } from './core/dialogs/dialog.service';
import { UtilService } from './core/services/util/util.service';
import { SummarizeNamePipeComponent } from './summarize-name.pipe';

// Swiper
import { register } from 'swiper/element/bundle';
register();


@Component({
  selector: 'lessor-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslationLibModule,
    SummarizeNamePipeComponent
  ],
  providers: [
    CrashlyticsService,
    AnalyticsService,
    SideMenuService,
    CrashlyticsService,
    UtilService,
    DialogService
  ],
})
export class AppComponent implements OnInit {
  sideMenuList: WritableSignal<SideMenu[]>;

  selectSportCenterUsername = this.store.selectSignal(
    selectSportCenterUsername
  );
  selectSportCenterName = this.store.selectSignal(selectSportCenterName);
  selectSportCenterImage = this.store.selectSignal(selectSportCenterImage);

  version = packageJson?.version ?? '0.0.0';

  selectResponsavelName = this.store.selectSignal(selectResponsavelName);

  isDevelopment = signal(!lessorEnvironment.production);
  environmentTitle = signal(lessorEnvironment.title);

  isPWA = signal(this.platformDetectionService.isPWA());

  constructor(
    public menuCtrl: MenuController,
    private store: Store,
    private crashlyticsService: CrashlyticsService,
    private analyticsService: AnalyticsService,
    private authFacade: AuthFacade,
    private vibrationService: VibrationService,
    private router: Router,
    private sideMenuService: SideMenuService,
    private platformDetectionService: PlatformDetectionService,
    private notificationListenersService: NotificationListenersService
  ) {
    SplashScreen.hide();
    this.init();
  }

  init() {
    // Load Icons
    configureIcons();
    this.initSideMenu();
    this.setSettingsSocialSport();
    this.initPathName();
  }

  initSideMenu() {
    this.sideMenuService
      .getSideMenu$()
      .pipe(distinctUntilChanged())
      .subscribe((sideMenu) => {
        this.sideMenuList = signal(sideMenu);
      });
  }

  ngOnInit(): void {    
    this.requestGeolocationPermission();
    
    // Firebase Initialization
    this.crashlyticsService.initFb();
    this.notificationListenersService.init();
    this.analyticsService.init();
  }

  initPathName(): void {
    localStorage.setItem(
      LocalStorageItem.INIT_APPLICATION_WITH_PATHNAME,
      window.location.pathname
    );
  }

  setSettingsSocialSport() {
    const settings = {
      canal: Canal.SPORT_CENTER,
      sportCenterUsername: this.selectSportCenterUsername(),
    } as SettingsSocialSport;
    this.store.dispatch(setSettingsSocialSport({ settings }));
  }

  async logout() {
    this.vibrationService.hapticsImpactMedium();
    this.authFacade.logout();
    this.forceCloseMenu();
    this.store.dispatch(setProfileLessor({ lessorUser: {} as LessorUser }));
  }

  forceCloseMenu() {
    const menu = document.querySelector(
      'ion-menu#lessorMenuId'
    ) as HTMLIonMenuElement | null;
    if (menu?.close) {
      menu.close();
    }
  }

  public async getStatus(): Promise<AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.getStatus();

    // { status: 'authorized' } for example
    return response;
  }

  async requestGeolocationPermission() {
    if(Capacitor.getPlatform() !== 'web') {
      await Geolocation.requestPermissions(); 
    }
  }

  redirectTo(link: string) {
    this.router.navigate([link]);
  }

  redirectPricingPage() {
    this.forceCloseMenu();
    this.router.navigate(['pricing']);
  }

}
