import {
  EventsCourtSchedulerResponse,
  EventsSchedulerResponse,
  EventsSchedulerStatusEnum,
  ProvideCourtNewRentResponse,
  RentEventResponse,
  SchedulerDayResponse
} from '@libs/entity-lib';
import { createReducer, on } from '@ngrx/store';
import * as fromScheduler from './scheduler.actions';

export interface State {
  loading: boolean;
  schedulerDay: SchedulerDayResponse | null;
  courtEvent: EventsCourtSchedulerResponse | null;
  rentEvent: RentEventResponse | null;
  error: any | null;
  newRentInfo?: ProvideCourtNewRentResponse;
}

export const initialState: State = {
  loading: false,
  schedulerDay: null,
  rentEvent: null,
  courtEvent: null,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(fromScheduler.fetchScheduler, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(fromScheduler.fetchSchedulerSuccess, (state, { schedulerDay }) => {
    let currentCourtEvent = schedulerDay.courtEvents.find(
      (courtEvent) => courtEvent.courtName === state.courtEvent?.courtName
    );

    if (!currentCourtEvent) {
      currentCourtEvent = schedulerDay.courtEvents?.[0] ?? null;
    }

    const updatedSchedulerDay = {
      ...schedulerDay,
      courtEvents: currentCourtEvent
        ? schedulerDay.courtEvents.map((courtEvent) =>
            courtEvent.courtName === currentCourtEvent?.courtName
              ? currentCourtEvent
              : courtEvent
          )
        : schedulerDay.courtEvents,
    };

    return {
      ...state,
      loading: false,
      schedulerDay: updatedSchedulerDay,
      courtEvent: currentCourtEvent,
      error: null
    };
  }),
  on(
    fromScheduler.setEventSelected,
    (state, { eventScheduler, dateEvent }) => ({
      ...state,
      schedulerDay: {
        eventScheduler,
        date: dateEvent,
        courtEvents: state.schedulerDay?.courtEvents || [],
      },
    })
  ),
  on(fromScheduler.fetchEventRent, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(fromScheduler.fetchEventRentSuccess, (state, { rentEvent }) => ({
    ...state,
    rentEvent,
    loading: false,
    error: null
  })),
  on(fromScheduler.fetchSchedulerFailed, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(fromScheduler.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(fromScheduler.updateCourtEvent, (state, { nameCourt }) => {
    const scheduleDay = JSON.parse(JSON.stringify(state.schedulerDay));

    const index =
      state.schedulerDay?.courtEvents.findIndex(
        (courtEvent: EventsCourtSchedulerResponse) => {
          return courtEvent.courtName === nameCourt;
        }
      ) ?? -1;

    if (index >= 0) {
      return { ...state, courtEvent: scheduleDay.courtEvents[index] };
    }

    return { ...state };
  }),
  on(fromScheduler.deleteRent, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(fromScheduler.deleteRentSuccess, (state, { idRent }) => {
    const courtEvent = JSON.parse(JSON.stringify(state.courtEvent));

    if (courtEvent?.events) {
      const index = courtEvent.events.findIndex(
        (obj: EventsSchedulerResponse) => {
          return obj.rent?.idRent == idRent;
        }
      );

      if (index >= 0) {
        courtEvent.events[index].rent.idRent = null;
        courtEvent.events[index].status = EventsSchedulerStatusEnum.DISPONIVEL;
      }
    }

    return { ...state, courtEvent, loading: false, error: null };
  }),
  on(fromScheduler.rentApproveRequest, (state) => ({
    ...state,
    loading: true
  })),
  on(fromScheduler.rentApproveSuccessfully, (state, { rentApproval }) => {

    const status = rentApproval.approve
      ? EventsSchedulerStatusEnum.AGENDADO
      : EventsSchedulerStatusEnum.DISPONIVEL;
    const rentEvent = JSON.parse(JSON.stringify(state.rentEvent));
    rentEvent.status = status;

    if (!rentApproval.approve) {
      rentEvent.players = [];
      rentEvent.playersRegitered = 0;
      rentEvent.userOwner = '';
    }

    return { ...state, rentEvent, loading: false, error: null };
  }),
  on(fromScheduler.rentApproveRequestFailed, (state) => ({
    ...state,
    loading: false,
  })),
  on(fromScheduler.deletePlayer, (state) => ({
    ...state,
    loading: true
  })),

  on(fromScheduler.updateRent, (state) => ({
    ...state,
    loading: true,
  })),
  on(fromScheduler.updateRentSuccessfully, (state, { response }) => {
    // Check if there is an existing rentEvent in the state
    const rentEvent = state.rentEvent;
    
    if (!rentEvent) {
      // If rentEvent does not exist, just return the current state without any changes
      return state;
    }
    
    // If rentEvent exists, update it with the new maximumNumberOfPlayers
    return {
      ...state,
      loading: false,
      rentEvent: {
        ...rentEvent,
        maximumNumberOfPlayers: response.maximumNumberOfPlayers
      },
      error: null
    };
  }),  
  on(fromScheduler.updateRentFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),

  on(fromScheduler.fetchNewRentInfo, (state) => ({
    ...state,
    loading: true,
  })),
  on(fromScheduler.fetchNewRentInfoSuccess, (state, { response }) => ({
    ...state,
    newRentInfo: response,
    loading: false,
    error: null
  })),
  on(fromScheduler.fetchNewRentInfoFailed, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),

  on(fromScheduler.addPlayer, (state) => ({
    ...state,
    loading: true,
  })),
  on(fromScheduler.addPlayerSuccess, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(fromScheduler.addPlayerFailed, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(fromScheduler.resetRentEvent, (state) => ({
    ...state,
    rentEvent: null,
    loading: false,
  })),
);
